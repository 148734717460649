'use client'

import { useCallback, useEffect, useState } from 'react'
import { getCookie } from 'cookies-next'
import { usePathname } from 'next/navigation'
import { useTTSControl } from '@store/tts'

export function useAuth(): {
	login(): void
	logout(): void
	isLogin: boolean
}
export function useAuth() {
	// @warn suspense 필수
	const pathname = usePathname()
	const [isLogin, setIsLogin] = useState(() => {
		return !!getCookie(process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME)
	})
	const loginUrl = process.env.NEXT_PUBLIC_AUTH_LOGIN_URL
	const logoutUrl = process.env.NEXT_PUBLIC_AUTH_LOGOUT_URL
	const searchParamName = process.env.NEXT_PUBLIC_AUTH_URL_PARAM_NAME
	const siteUrl = process.env.NEXT_PUBLIC_SITE_URL

	const { isTTSPlaying, setIsTTSPlaying } = useTTSControl()

	const login = useCallback(() => {
		const url = new URL(loginUrl)
		url.searchParams.set(searchParamName, `${siteUrl}${pathname}`)

		if (isTTSPlaying) {
			setIsTTSPlaying(false)
		}

		window.location.href = url.toString()
	}, [isTTSPlaying, loginUrl, pathname, searchParamName, setIsTTSPlaying, siteUrl])

	const logout = useCallback(() => {
		const url = new URL(logoutUrl)
		url.searchParams.set(searchParamName, `${siteUrl}${pathname}`)

		window.location.href = url.toString()
	}, [logoutUrl, pathname, searchParamName, siteUrl])

	useEffect(() => {
		setIsLogin(!!getCookie(process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME))
	}, [])

	return {
		login,
		logout,
		isLogin,
	}
}
