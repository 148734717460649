'use client'

import useModal from '@components/layout/Modal'
import UIButton from '@components/ui/UIButton'
import { colorCustom } from '@components/ui/UIButton/UIButtonRoot'
import useIsMounted from '@hooks/useIsMounted'
import { useNotification } from '@lib/notification'
import { SxProps } from '@mui/joy/styles/types'
import { useSubscribeJournalistMutation } from '@services/api/Subscribe/Journalist/mutation'
import { useSubscribePackageMutation } from '@services/api/Subscribe/Package/mutation'
import { useSubscribeProgramMutation } from '@services/api/Subscribe/Program/mutation'
import { useAuthControl } from '@store/auth'
import { theme } from '@ui/style'
import { pxToRem } from '@ui/style/muiTheme'
import { MouseEvent, useEffect, useState } from 'react'
import { Icon16Check } from 'src/assets/icons/Icon16Check'
import { Icon16Plus } from 'src/assets/icons/Icon16Plus'

export type SubscribeButtonTarget = 'journalist' | 'package' | 'program'
export type SubscribeButtonProps = {
	title?: string
	label?: '구독' | '전체 구독' | undefined
	isSubscribed?: boolean
	target?: SubscribeButtonTarget
	idx?: string | string[]
	color?: 'primary' | 'neutral000' | 'neutral000Main'
	variant?: 'contained' | 'outlined' | 'text'
	sx?: SxProps
	onSuccess?: () => void
	isDetailHeader?: boolean
}

/**
 *
 * @param isDetailHeader 비디오뉴스, 연재/코너 상단헤더영역에서만 버튼 컬러가 달라짐
 */
export const SubscribeButton = ({
	title,
	target,
	idx,
	isSubscribed = false,
	label = '구독',
	color,
	onSuccess,
	sx,
	isDetailHeader = false,
	variant,
	...props
}: SubscribeButtonProps) => {
	const isMounted = useIsMounted()
	const [subscribe, setSubscribe] = useState<boolean>()
	const { auth } = useAuthControl()
	const confirm = useModal()
	const { notify } = useNotification()
	const iconColor = color && colorCustom[color].currentText

	const { mutateAsync: subscribeJournalist } = useSubscribeJournalistMutation()
	const { mutateAsync: subscribePackage } = useSubscribePackageMutation()
	const { mutateAsync: subscribeProgram } = useSubscribeProgramMutation()

	const fetchQuery = async (key?: string, value?: string | string[]) => {
		if (!key || !value) return undefined

		const valueArray = Array.isArray(value) ? value : [value]
		const url = window.location.href

		switch (key) {
			case 'journalist':
				return subscribeJournalist({ journalistIdx: valueArray, url })
			case 'package':
				return subscribePackage({ packageIdx: valueArray, url })
			case 'program':
				return subscribeProgram({ programIdx: valueArray, url })
			default:
				return undefined
		}
	}
	const toggleSubscribe = async () => {
		const result = await fetchQuery(target, idx)

		if (result?.data) {
			setSubscribe((current) => !current)
			if (!subscribe) {
				notify(`${title ? `${title}\n` : ''}구독이 완료되었습니다.`)
			}
			if (onSuccess) {
				onSuccess()
			}
		}
	}

	const handleSubscribe = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.preventDefault()
		e.stopPropagation()
		e.nativeEvent.stopImmediatePropagation()

		if (!auth) {
			confirm.login()
		} else if (!subscribe) {
			toggleSubscribe()
		} else {
			confirm.open({
				message: `${title ? `${title}의\n` : ''}구독을 취소하시겠습니까?`,
				buttons: [
					{ text: '아니오' },
					{
						text: '예',
						onClick: (close) => {
							close()
							toggleSubscribe()
						},
					},
				],
			})
		}
	}
	useEffect(() => {
		setSubscribe(isSubscribed)
	}, [isSubscribed])
	return (
		<UIButton
			{...props}
			disabled={!isMounted}
			variant={
				variant ||
				(isDetailHeader
					? auth && (subscribe ?? isSubscribed)
						? 'contained'
						: 'outlined'
					: undefined)
			}
			color={
				color ||
				(!isDetailHeader
					? auth && (subscribe ?? isSubscribed)
						? 'light'
						: 'primary'
					: auth && (subscribe ?? isSubscribed)
					? 'neutral000Main'
					: 'neutral000')
			}
			onClick={handleSubscribe}
			sx={{
				minWidth: pxToRem(61),
				height: pxToRem(32),
				flexShrink: 0,
				paddingLeft: pxToRem(8),
				paddingRight: pxToRem(10),
				width:
					label === '전체 구독'
						? subscribe
							? pxToRem(103)
							: pxToRem(88)
						: label === '구독'
						? subscribe
							? pxToRem(76)
							: pxToRem(61)
						: 'auto',
				letterSpacing: '-0.042px',
				boxSizing: 'border-box',
				...(color === 'primary' && {
					'&:active': {
						background:
							auth && (subscribe ?? isSubscribed)
								? '#B7D4FF'
								: theme.color.colPointBlue,
					},
					[theme.mediaQueries.hover]: {
						'&:hover': {
							background:
								auth && (subscribe ?? isSubscribed)
									? '#B7D4FF'
									: theme.color.colPointBlue,
						},
					},
				}),
				svg: {
					stroke: 'inherit',
				},
				'&>*:not(:first-child)': {
					marginLeft: pxToRem(2),
				},
				...sx,
				'&>*': {
					flex: 'none',
				},
			}}
		>
			{auth && (subscribe ?? isSubscribed) ? (
				<Icon16Check color={iconColor} />
			) : (
				<Icon16Plus color={iconColor} />
			)}
			<span>{auth && (subscribe ?? isSubscribed) ? `${label} 중` : label}</span>
		</UIButton>
	)
}
