var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ImG8GSME_84LZ9HMHGNjY"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,

	enabled: process.env.NODE_ENV === 'production',

	// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
	tracesSampleRate: 1,

	release: `${process.env.NEXT_PUBLIC_SENTRY_PROJECT}@${process.env.APP_VERSION}`,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	/** 예외처리 */
	ignoreErrors: [
		'invalid version specified',
		'Failed to fetch',
		'Load failed',
		'globalThis is not defined',
		'/_next/static/media/ff840cfebfb63b0c.p.woff2',
		"Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
		'Notification.requestPermission is not a function',
		/\/_next\/static\/media\/ff840cfebfb63b0c\.p\.woff2/i,
		/removeChild.*on.*Node/i,
		/Notification\.requestPermission/i,
		/Unexpected token '\.'?/i,
		/Cannot convert undefined or null to object/i,
		/\(type=error\).*captured/i,
	],

	/** 허용 URL */
	allowUrls: [
		/^https:\/\/news\.jtbc\.co\.kr(\/.*)?$/, // https://news.jtbc.co.kr
	],

	/** 제한 URL */
	denyUrls: [
		/^https:\/\/local-news\.jtbc\.co\.kr(\/.*)?$/, // https://local-news.jtbc.co.kr
		/^https:\/\/stg-news\.jtbc\.co\.kr(\/.*)?$/, // https://stg-news.jtbc.co.kr
		/^https:\/\/dev-news\.jtbc\.co\.kr(\/.*)?$/, // https://dev-news.jtbc.co.kr
	],
})
