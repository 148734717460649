'use client'

const IconAppStore = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M20.1604 15.3896C20.1682 14.7849 20.3288 14.1921 20.6273 13.6662C20.9258 13.1403 21.3524 12.6984 21.8676 12.3817C21.5403 11.9144 21.1086 11.5297 20.6067 11.2584C20.1048 10.9871 19.5465 10.8365 18.9763 10.8187C17.7598 10.691 16.5805 11.5466 15.9606 11.5466C15.3287 11.5466 14.3743 10.8313 13.3465 10.8525C12.6817 10.874 12.0338 11.0673 11.466 11.4136C10.8981 11.7599 10.4297 12.2474 10.1062 12.8286C8.70519 15.2543 9.75023 18.8192 11.0923 20.7799C11.7638 21.74 12.5485 22.8125 13.5754 22.7745C14.5802 22.7328 14.9554 22.1337 16.1683 22.1337C17.37 22.1337 17.7221 22.7745 18.7697 22.7503C19.848 22.7328 20.5273 21.7859 21.1752 20.8167C21.6576 20.1326 22.0289 19.3765 22.2752 18.5764C21.6488 18.3115 21.1142 17.868 20.7381 17.3013C20.3621 16.7346 20.1611 16.0697 20.1604 15.3896Z"
				fill="black"
			/>
			<path
				d="M18.182 9.52868C18.7698 8.82294 19.0595 7.91584 18.9893 7C18.0912 7.09433 17.2615 7.5236 16.6657 8.20226C16.3744 8.5338 16.1513 8.91951 16.0091 9.33733C15.8669 9.75516 15.8085 10.1969 15.8372 10.6373C16.2864 10.6419 16.7308 10.5446 17.137 10.3525C17.5432 10.1605 17.9004 9.87881 18.182 9.52868Z"
				fill="black"
			/>
		</svg>
	)
}

export default IconAppStore
