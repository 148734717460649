import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import Box from '@mui/joy/Box'
import { NewsHomeTopKeywordResponseVo } from '@schemas/non-auth'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import 'swiper/css'
import 'swiper/css/free-mode'
import { FreeMode } from 'swiper/modules'
import { Swiper } from 'swiper/react'
import { IssueLayout, SlideItem } from './styled'

export interface IssueBoxProps {
	topKeyword?: Array<NewsHomeTopKeywordResponseVo>
}
const IssueBox = ({ topKeyword }: IssueBoxProps) => {
	const { darkMode } = useDarkModeControl()
	return (
		<IssueLayout className="header__issueBox">
			<UIFlex direction="row" align="flex-start">
				<Box display={{ xs: 'none', md: 'block' }} flexShrink={0} mr={pxToRem(24)}>
					<UIText
						level="Caption"
						weight="700"
						color={darkMode ? color.colGray1 : color.colBlack}
					>
						이슈
					</UIText>
				</Box>
				<Swiper modules={[FreeMode]} spaceBetween={24} slidesPerView="auto" freeMode>
					{topKeyword?.map(
						({ keywordName, linkUrlInfo }: NewsHomeTopKeywordResponseVo) => {
							return (
								keywordName &&
								keywordName !== '' && (
									<SlideItem
										key={`keyword-${linkUrlInfo?.linkCode}-${keywordName}`}
									>
										<UIText
											level="Caption"
											weight="500"
											color={darkMode ? color.colGray1 : color.colBlack}
											{...{ component: UILink, link: linkUrlInfo }}
											sx={{
												'&:hover': {
													color: color.colPointBlue,
												},
											}}
										>
											{`#${keywordName}`}
										</UIText>
									</SlideItem>
								)
							)
						},
					)}
				</Swiper>
			</UIFlex>
		</IssueLayout>
	)
}
export default IssueBox
