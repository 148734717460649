'use client'

import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Box, styled } from '@mui/joy'
import UIFlex from '@components/ui/UIFlex'
import UIImage from '@components/ui/UIImage'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import zIndexes from '@ui/style/zIndexes.theme'
import pxToRem from '@utils/pxToRem'
import { appInterface } from '@store/isApp'
import { useRecoilValue } from 'recoil'
import { dayjs } from '@utils/date'
import { useLocalStorage } from '@hooks/useLocalStorage'

const BG_COLOR = '#242424'

export interface AppSplashProps {
	image_file: string // 배너 이미지 url
	click_through: string // 배너 클릭 주소
	click_tracking?: string // 배너 클릭 이벤트 발생시 호출 url 없으면 호출하지 않음
	impression_tracking: string // 배너 노출 이벤트 발생시 호출 url
	// width: string // 배너 폭
	// height: string // 배너 높이
	// title?: string // 제목
	bgcolor: string // 배경색코드
	// description?: string // 설명문구(개행은 \n으로 표시)
	alternative_text: string // 대체 텍스트
}

const SplashButton = styled(UIText)(() => ({
	fontsize: pxToRem(16),
	lineHeight: pxToRem(24),
	color: color.colWhite,
}))

const AppSplash = ({
	image_file,
	click_through,
	click_tracking,
	impression_tracking,
	bgcolor,
	alternative_text,
}: AppSplashProps) => {
	const nativeEvent = useRecoilValue(appInterface)
	const [open, setOpen] = useState<boolean>(false)
	const [getDateHideAppSplashAds, setDateHideAppSplashAds] =
		useLocalStorage('getDateHideAppSplashAds')

	const handleClick = useCallback(() => {
		if (click_tracking) {
			fetch(click_tracking)
		}
	}, [click_tracking])

	const handelClose = () => {
		setOpen(false)
	}

	const handleTodayHide = () => {
		const todayEnd = dayjs().endOf('day') // 오늘의 끝 (23:59:59)
		setDateHideAppSplashAds(todayEnd.toISOString())
		handelClose()
	}

	/** 가로전환 인터페이스 호출 */
	const handleDeviceOrientation = (isPrevent: boolean) => {
		nativeEvent({
			key: 'requestDeviceOrientation',
			value: {
				isPrevent,
			},
		})
	}
	/** 새로고침 막기 인터페이스 호출 */
	const handleDeviceRefreshValue = (enableRefresh: boolean) => {
		nativeEvent({
			key: 'setRefreshValue',
			value: {
				enableRefresh,
			},
		})
	}

	useEffect(() => {
		if (open) {
			handleDeviceOrientation(true)
			handleDeviceRefreshValue(false)
			if (impression_tracking) {
				fetch(impression_tracking)
			}

			document.body.classList.add('scroll-lock-all')
		} else {
			handleDeviceOrientation(false)
			handleDeviceRefreshValue(true)
			document.body.classList.remove('scroll-lock-all')
		}

		return () => {
			handleDeviceOrientation(false)
			handleDeviceRefreshValue(true)
			document.body.classList.remove('scroll-lock-all')
		}
	}, [open])

	useLayoutEffect(() => {
		const isHideAds =
			getDateHideAppSplashAds && dayjs().isBefore(dayjs(`${getDateHideAppSplashAds}`))
		if (!isHideAds) {
			setOpen(true)
		}
	}, [getDateHideAppSplashAds])

	if (!open) {
		return null
	}

	const src = image_file.replace('http://', 'https://')

	return (
		<UIFlex
			position="fixed"
			top={0}
			left={0}
			right={0}
			bottom={0}
			width="100%"
			height="100%"
			zIndex={zIndexes.top}
			bgcolor={BG_COLOR}
		>
			<Box flexGrow={1}>
				<UILink
					href={click_through}
					target="_blank"
					display="block"
					height="100%"
					onClick={handleClick}
				>
					<UIImage
						src={src}
						style={{ width: '100%' }}
						ratio="9/16"
						isContain
						alt={alternative_text ?? ''}
						sx={{
							height: '100%',
							'.MuiAspectRatio-content': {
								height: '100%',
								paddingBottom: '0',
							},
							img: {
								objectPosition: '50%!important',
								backgroundColor: `${BG_COLOR} !important`,
							},
						}}
					/>
				</UILink>
			</Box>
			<UIFlex
				bgcolor={color.colBlack}
				flexDirection="row"
				flexShrink={0}
				justifyContent="space-between"
				alignItems="center"
				height={61}
				padding={`${pxToRem(16)} ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(20)}`}
			>
				<SplashButton component="button" onClick={handleTodayHide}>
					오늘은 그만 보기
				</SplashButton>
				<SplashButton component="button" onClick={handelClose}>
					닫기
				</SplashButton>
			</UIFlex>
		</UIFlex>
	)
}
export default AppSplash
