'use client'

import UILink from '@components/ui/UILink'
import { Box, ListProps, styled } from '@mui/joy'
import { MenuResponseVo } from '@schemas/non-auth'
import pxToRem from '@utils/pxToRem'
import 'swiper/css'
import 'swiper/css/free-mode'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MenuItem, MenuLink } from './styled'
import { useCallback } from 'react'
import { scrollToTopAndEnableAutoScrollRestoration } from '@utils/scroll'

export interface NavMenuProps extends ListProps {
	menu: Array<MenuResponseVo>
	darkMode?: boolean
}

export const SlideItem = styled(SwiperSlide)(({ theme }) => ({
	width: 'auto!important',
}))

const NavMenu = ({ menu, darkMode }: NavMenuProps) => {
	const drawMenu = useCallback(
		(item: MenuResponseVo) => (
			<SlideItem key={`nav-${item.menuIdx}`}>
				<MenuItem>
					<MenuLink
						className={item.linkUrlInfo.linkCode === 'LIVE' ? 'dotted' : ''}
						darkMode={darkMode}
						link={item.linkUrlInfo}
						onClick={scrollToTopAndEnableAutoScrollRestoration}
					>
						{item.menuName}
					</MenuLink>
				</MenuItem>
			</SlideItem>
		),
		[darkMode],
	)

	return (
		<Box
			display={{ xs: 'none', lg: 'block' }}
			overflow="hidden"
			mr={pxToRem(28)}
			sx={{
				'& .swiper:not(.swiper-initialized)': {
					'& .swiper-wrapper': {
						'&>*:not(:first-child)': {
							marginLeft: {
								xs: pxToRem(20),
								xl: pxToRem(24),
							},
						},
					},
				},
			}}
		>
			<Swiper
				modules={[FreeMode]}
				spaceBetween={20}
				slidesPerView="auto"
				freeMode
				breakpoints={{
					1280: {
						spaceBetween: 24,
					},
				}}
				onInit={() => {
					scrollToTopAndEnableAutoScrollRestoration()
				}}
			>
				{menu.map(drawMenu)}
			</Swiper>
		</Box>
	)
}
export default NavMenu
