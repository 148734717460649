'use client'

import LoadingSpinner from '@components/layout/Loading/Spinner'
import { usePathname } from 'next/navigation'

const Loading = () => {
	const pathname = usePathname()
	const isShorts = pathname.includes('/shorts')

	return isShorts ? null : <LoadingSpinner />
}

export default Loading
