'use client'

import UIContainer from '@components/ui/UIContainer'
import HeaderGroup from '../HeaderGroup'
import { HeaderBox } from '../styled'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import pxToRem from '@utils/pxToRem'
import UIButton from '@components/ui/UIButton'
import color from '@ui/style/color.theme'
import Icon20ServiceArrow from 'src/assets/icons/Icon20ServiceArrow'
import { theme } from '@ui/style'
import UIDivider from '@components/ui/UIDivider'
import IconAppStore from 'src/assets/icons/IconAppStore'
import IconPlayStore from 'src/assets/icons/IconPlayStore'
import { useRecoilValue } from 'recoil'
import { componentVisibilityState } from '@page/service/_component/store/visibilityStore'
import { useIsAppControl } from '@store/isApp'

const ServiceIntroHeader = () => {
	const { appInfo, isApp } = useIsAppControl()
	const isIosApp = isApp && appInfo?.DType === 'I'
	const isVisible = useRecoilValue(componentVisibilityState)
	return (
		<HeaderBox>
			<HeaderGroup>
				<UIContainer>
					<UIFlex
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
						sx={{
							height: {
								xs: pxToRem(52),
								md: 'auto',
							},
							py: {
								md: pxToRem(18),
							},
						}}
					>
						<UILink
							href="/"
							sx={{
								display: 'block',
								width: {
									xs: pxToRem(118.2),
									md: pxToRem(141.844),
									xl: pxToRem(144),
								},
								height: {
									xs: pxToRem(28.333),
									md: pxToRem(34),
									xl: pxToRem(48),
								},
								background:
									'url(/images/logo/mo_blue_logo_2x.png) no-repeat center / contain',
							}}
						>
							<UIText readonly>JTBC News</UIText>
						</UILink>
						<UIFlex
							flexDirection="row"
							alignItems="center"
							sx={{ '.store-button + .store-button': { marginLeft: pxToRem(4) } }}
						>
							{!isVisible && (
								<>
									<UILink
										href="https://apps.apple.com/us/app/jtbc-%EB%89%B4%EC%8A%A4/id494497008"
										target="_blank"
										className="store-button"
									>
										<UIText readonly>앱스토어 바로가기</UIText>
										<IconAppStore />
									</UILink>
									{!isIosApp && (
										<UILink
											href="https://play.google.com/store/apps/details?id=com.jtbc.news"
											target="_blank"
											className="store-button"
										>
											<UIText readonly>구글 플레이 바로가기</UIText>
											<IconPlayStore />
										</UILink>
									)}
									<UIDivider
										orientation="vertical"
										sx={{
											height: pxToRem(12),
											alignSelf: 'auto',
											margin: `0 ${pxToRem(12)}`,
										}}
									/>
								</>
							)}

							<UILink href="/">
								<UIButton
									variant="outlined"
									sx={{
										width: {
											xs: 'auto',
											md: pxToRem(99),
										},
										padding: {
											xs: 0,
											md: `${pxToRem(5)} ${pxToRem(3)} ${pxToRem(
												5,
											)} ${pxToRem(11)}`,
										},
										display: 'flex',
										alignItems: 'center',
										borderRadius: {
											md: '999px',
										},
										[theme.breakpoints.down('md')]: {
											border: '0!important',
										},
									}}
								>
									<UIText
										sx={{
											color: color.colDarkBlue,
											fontSize: pxToRem(14),
											lineHeight: pxToRem(20),
											fontWeight: 600,
											span: {
												display: {
													md: 'none',
												},
											},
										}}
									>
										JTBC 뉴스<span> 홈</span>
									</UIText>
									<Icon20ServiceArrow />
								</UIButton>
							</UILink>
						</UIFlex>
					</UIFlex>
				</UIContainer>
			</HeaderGroup>
		</HeaderBox>
	)
}

export default ServiceIntroHeader
