'use client'

import { BarLink, ButtonText } from '../TabBarRoot'
import { UITextProps } from '@components/ui/UIText'
import IconTabBarMypage from 'src/assets/icons/IconTabBarMypage'
import { useAuthControl } from '@store/auth'
import { useState, useEffect } from 'react'
import { scrollToTopAndEnableAutoScrollRestoration } from '@utils/scroll'

const MypageController = ({ textColor }: { textColor: UITextProps['color'] }) => {
	const { auth } = useAuthControl()
	const [origin, setOrigin] = useState<string>('')

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setOrigin(window.location.origin)
		}
	}, [])

	return (
		<BarLink
			href={
				auth
					? '/mypage'
					: `${process.env.NEXT_PUBLIC_AUTH_LOGIN_URL}?targetUrl=${encodeURIComponent(
							`${origin}/mypage`,
					  )}`
			}
			onClick={scrollToTopAndEnableAutoScrollRestoration}
		>
			<IconTabBarMypage color={textColor} />
			<ButtonText color={textColor}>마이페이지</ButtonText>
		</BarLink>
	)
}
export default MypageController
