import { atom, selector } from 'recoil'

export const isTitleOffScreenState = atom<boolean>({
	key: 'isTitleOffScreenState',
	default: false,
})

export const isScrollDownState = atom<boolean>({
	key: 'isScrollDownState',
	default: false,
})

export const isShowTitleHeaderState = selector<boolean>({
	key: 'isShowTitleHeaderState',
	get: ({ get }) => {
		const isTitleOffScreen = get(isTitleOffScreenState)
		const isScrollDown = get(isScrollDownState)
		return isTitleOffScreen && isScrollDown
	},
})
