/**
 * 브라우저의 기본 스크롤 복원 동작을 활성화하는 함수
 */
export const enableAutoScrollRestoration = () => {
	window.history.scrollRestoration = 'auto'
}

/**
 * 페이지 최상단으로 스크롤을 이동시키는 함수
 */
export const scrollToTop = () => {
	window.history.scrollRestoration = 'manual'
	window.scrollTo(0, 0)
}

export const scrollToTopAndEnableAutoScrollRestoration = () => {
	scrollToTop()
	setTimeout(() => {
		enableAutoScrollRestoration()
	}, 300)
}
