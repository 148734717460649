const Icon20ServiceArrow = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path d="M8 6L12 10L8 14" stroke="#052D5A" />
		</svg>
	)
}

export default Icon20ServiceArrow
