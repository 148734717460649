const IconPlayStore = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M16.1669 15.6419L9.54297 22.6715C9.54297 22.6715 9.54297 22.6741 9.54297 22.6754C9.74578 23.4385 10.4426 24.0001 11.2708 24.0001C11.601 24.0001 11.9117 23.9104 12.1782 23.7544L12.199 23.7414L19.655 19.4395L16.1656 15.6406L16.1669 15.6419Z"
				fill="#EA4435"
			/>
			<path
				d="M22.868 14.4447L22.8615 14.4408L19.6425 12.5752L16.0166 15.802L19.6555 19.4396L22.8576 17.5922C23.4193 17.2893 23.8002 16.6977 23.8002 16.0152C23.8002 15.3327 23.4244 14.7489 22.868 14.4447Z"
				fill="#F9BC15"
			/>
			<path
				d="M9.54304 9.3291C9.50273 9.47601 9.48193 9.63072 9.48193 9.78933V22.2116C9.48193 22.3715 9.50273 22.5249 9.54304 22.6718L16.3945 15.8217L9.54304 9.3291Z"
				fill="#547DBF"
			/>
			<path
				d="M16.215 16.0007L19.6433 12.5737L12.1964 8.25612C11.926 8.09361 11.6101 8 11.2721 8C10.4439 8 9.74578 8.56293 9.54297 9.32738C9.54297 9.32738 9.54297 9.32738 9.54297 9.32868L16.215 16.0007Z"
				fill="#33A852"
			/>
		</svg>
	)
}

export default IconPlayStore
